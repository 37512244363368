import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavbarComponent from "./components/NavbarComponent";
import FooterComponent from "./components/FooterComponent";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import About from "./pages/About";
import InteriorDetails from "./pages/InteriorDetails";
import ExteriorDetails from "./pages/ExteriorDetails";
import MasonryDetails from "./pages/MasonryDetails";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavbarComponent />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/interieur" element={<InteriorDetails />} />
            <Route path="/services/exterieur" element={<ExteriorDetails />} />
            <Route path="/services/maconnerie" element={<MasonryDetails />} />
          </Routes>
        </div>
        <FooterComponent />
      </div>
    </Router>
  );
}

export default App;
