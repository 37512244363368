import React, { useEffect } from "react";

const Projects = () => {
  useEffect(() => {
    const parseFacebookPlugins = () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    };

    if (!window.FB) {
      window.addEventListener("fbAsyncInit", parseFacebookPlugins);
    } else {
      parseFacebookPlugins();
    }

    return () => {
      window.removeEventListener("fbAsyncInit", parseFacebookPlugins);
    };
  }, []);

  return (
    <div>
      {/* SDK Root Element */}
      <div id="fb-root"></div>
      <h1
        style={{
          textAlign: "center",
          fontSize: "28px",
          marginBottom: "15px",
          fontWeight: "bold",
          color: "#2c3e50",
          textTransform: "uppercase",
          letterSpacing: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          style={{
            borderBottom: "3px solid #3498db",
            paddingBottom: "8px",
          }}
        >
          Mes Réalisations
        </span>
      </h1>

      <div className="posts-container">
        {/* Nouveau post */}
        <div
          className="fb-post"
          data-href="https://www.m.facebook.com/sf.renovation.2024/posts/pfbid027m6gctrhEo1zqnXvbBayQgcYhXcup3qtqyGYPrgjUEX3jh456j6XBbsakugpaoWnl"
          data-width="100%"
          data-show-text="true"
        ></div>
        {/* Post 1 */}
        <div
          className="fb-post"
          data-href="https://www.m.facebook.com/sf.renovation.2024/posts/pfbid0bZb1CWRE3ecLAfVA6FCXQpJAWjdmfnxEtCQTSbtG6VSEGkjbiDuEi8D9uH83y6mal"
          data-width="100%"
          data-show-text="true"
        ></div>
        {/* Post 2 */}
        <div
          className="fb-post"
          data-href="https://www.m.facebook.com/sf.renovation.2024/posts/pfbid0Pise88eNTcrb3qUkWLhDTvWYHkYt67mo4y3dhmaDTKMfNSCRThA7PzXwJkadUBMJl"
          data-width="100%"
          data-show-text="true"
        ></div>
        {/* Post 3 */}
        <div
          className="fb-post"
          data-href="https://www.m.facebook.com/sf.renovation.2024/posts/pfbid02RXH4cfGkYjRR1osXz3ZYy1uRXzbNknmAGqXRvr7tMp2ZLjxUpaqBcuGDMJ9ZtDGCl"
          data-width="100%"
          data-show-text="true"
        ></div>
      </div>

      <style jsx>{`
        .posts-container {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          justify-content: center;
        }

        .fb-post {
          max-width: 500px; /* Taille maximale sur les écrans larges */
          width: 100%; /* Adapte la largeur à l'écran */
        }

        @media (max-width: 768px) {
          .posts-container {
            gap: 10px;
          }

          .fb-post {
            max-width: 100%; /* Utilisation de toute la largeur sur mobile */
          }
        }

        @media (max-width: 480px) {
          h1 {
            font-size: 20px;
          }

          .posts-container {
            flex-direction: column;
            align-items: center;
          }

          .fb-post {
            max-width: 95%; /* Légère marge sur les côtés */
          }
        }
      `}</style>
    </div>
  );
};

export default Projects;
