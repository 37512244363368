import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "contact",
    message: "",
    notRobot: false,
  });

  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "Le prénom est requis.";
    if (!formData.lastName.trim()) newErrors.lastName = "Le nom est requis.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Une adresse email valide est requise.";
    }
    if (!formData.phone.trim() || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Un numéro de téléphone valide est requis.";
    }
    if (!formData.message.trim())
      newErrors.message = "Le message ne peut pas être vide.";
    if (!formData.notRobot)
      newErrors.notRobot = "Veuillez confirmer que vous n'êtes pas un robot.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setIsSending(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Préparer les données pour EmailJS
    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
    };

    // Envoyer les données via EmailJS
    emailjs
      .send(
        "service_mf61be2", // Remplacez par votre Service ID
        "template_0sl9wx6", // Remplacez par votre Template ID
        templateParams,
        "aoGNb2tjhzxZnt8yB" // Remplacez par votre Public Key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSending(false);
          setSuccessMessage("Votre message a été envoyé avec succès !");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            subject: "contact",
            message: "",
            notRobot: false,
          });
        },
        (err) => {
          console.error("FAILED...", err);
          setIsSending(false);
          setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
        }
      );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 relative overflow-hidden p-6">
      <div className="bg-gray-800 shadow-lg rounded-lg p-10 max-w-3xl w-full">
        <h1 className="text-3xl font-bold text-center text-yellow-400 mb-4">
          Formulaire de Contact
        </h1>
        <p className="text-center text-gray-300 mb-6">
          Veuillez remplir vos informations et nous vous répondrons dans les
          plus brefs délais.
        </p>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex gap-4">
            <div className="w-1/2">
              <input
                type="text"
                name="firstName"
                placeholder="Prénom"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
              )}
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="lastName"
                placeholder="Nom"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
              )}
            </div>
          </div>

          <div>
            <input
              type="email"
              name="email"
              placeholder="Votre Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Téléphone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
            />
            {errors.phone && (
              <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
            )}
          </div>

          <div>
            <select
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
            >
              <option value="contact">Contact</option>
              <option value="devis">Devis</option>
            </select>
          </div>

          <div>
            <textarea
              name="message"
              placeholder="Votre Message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
              rows="4"
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">{errors.message}</p>
            )}
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="not-robot"
              name="notRobot"
              checked={formData.notRobot}
              onChange={handleChange}
            />
            <label htmlFor="not-robot" className="text-gray-300">
              Je ne suis pas un robot
            </label>
          </div>
          {errors.notRobot && (
            <p className="text-red-500 text-sm">{errors.notRobot}</p>
          )}

          <button
            type="submit"
            className="w-full bg-yellow-400 text-gray-900 px-6 py-3 font-semibold rounded-md hover:bg-yellow-300 transition"
            disabled={isSending}
          >
            {isSending ? "Envoi en cours..." : "Envoyer"}
          </button>
        </form>

        {successMessage && (
          <p className="text-green-500 text-center mt-4">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-red-500 text-center mt-4">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default Contact;
