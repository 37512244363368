import React from "react";

const FooterComponent = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="main-content bg-gray-800 text-white text-center p-4">
      <p>&copy; {currentYear} SF Rénovation - Tous droits réservés</p>
      <p>
        Propulsé par{" "}
        <a href="https://thomasdescamps.fr" target="_blank" rel="noreferrer">
          Thomas Descamps
        </a>
      </p>
    </footer>
  );
};

export default FooterComponent;
