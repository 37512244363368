import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaHome,
  FaTools,
  FaProjectDiagram,
  FaInfoCircle,
  FaPhone,
  FaBars,
  FaTimes,
} from "react-icons/fa";

const NavbarComponent = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // État pour gérer l'ouverture du menu mobile
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false); // État pour le sous-menu services

  // Ne pas afficher la navbar si on est sur la page d'accueil
  if (location.pathname === "/") {
    return null;
  }

  // Fonction pour ajouter la classe active aux liens correspondants
  const getLinkClass = (path) => {
    return location.pathname === path
      ? "font-bold text-xl text-yellow-500"
      : "hover:text-yellow-500";
  };

  // Fonction pour gérer l'ouverture/fermeture du menu mobile
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
    setIsServicesMenuOpen(false); // Ferme également le sous-menu lors de l'ouverture/fermeture du menu principal
  };

  // Fonction pour fermer le menu mobile lorsqu'un lien est cliqué
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setIsServicesMenuOpen(false);
  };

  // Fonction pour gérer l'ouverture/fermeture du sous-menu services
  const toggleServicesMenu = () => {
    setIsServicesMenuOpen((prev) => !prev);
  };

  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="text-2xl font-bold">
          <span className="text-[#e7ab27]">SF</span> Rénovation
        </Link>

        {/* Menu hamburger pour les écrans mobiles */}
        <div className="block lg:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            {isMobileMenuOpen ? (
              <FaTimes className="w-8 h-8" />
            ) : (
              <FaBars className="w-8 h-8" />
            )}
          </button>
        </div>

        {/* Liens de navigation pour grands écrans */}
        <div className="hidden lg:flex space-x-6">
          <Link to="/" className={getLinkClass("/")}>
            Accueil
          </Link>
          {/* Sous-menu Services */}
          <div className="relative group">
            <button
              onClick={toggleServicesMenu}
              className="hover:text-yellow-500 flex items-center"
            >
              Services
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`absolute bg-white text-gray-800 rounded shadow-lg mt-2 z-50 ${
                isServicesMenuOpen ? "block" : "hidden"
              }`}
            >
              <Link
                to="/services/interieur"
                className="block px-4 py-2 hover:bg-yellow-500 hover:text-white"
                onClick={closeMobileMenu}
              >
                Rénovation intérieur
              </Link>
              <Link
                to="/services/exterieur"
                className="block px-4 py-2 hover:bg-yellow-500 hover:text-white"
                onClick={closeMobileMenu}
              >
                Rénovation extérieur
              </Link>
              <Link
                to="/services/maconnerie"
                className="block px-4 py-2 hover:bg-yellow-500 hover:text-white"
                onClick={closeMobileMenu}
              >
                Maçonnerie
              </Link>
            </div>
          </div>
          <Link to="/projects" className={getLinkClass("/projects")}>
            Réalisations
          </Link>
          <Link to="/about" className={getLinkClass("/about")}>
            À propos
          </Link>
          <Link to="/contact" className={getLinkClass("/contact")}>
            Contact
          </Link>
        </div>
      </div>

      {/* Menu mobile */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-95 z-30 p-4">
          {/* Bouton de fermeture */}
          <div className="flex justify-end">
            <button
              onClick={toggleMobileMenu}
              className="text-white text-2xl focus:outline-none"
            >
              <FaTimes className="w-8 h-8" />
            </button>
          </div>

          {/* Liens du menu mobile */}
          <div className="flex flex-col items-center mt-16 space-y-8">
            <Link
              to="/"
              className="flex items-center text-lg font-semibold text-yellow-400 hover:text-yellow-500"
              onClick={closeMobileMenu}
            >
              <FaHome className="mr-3" /> Accueil
            </Link>
            {/* Sous-menu Services */}
            <div className="flex flex-col items-center">
              <button
                onClick={toggleServicesMenu}
                className="flex items-center text-lg font-semibold text-yellow-400 hover:text-yellow-500"
              >
                <FaTools className="mr-3" /> Services
              </button>
              {isServicesMenuOpen && (
                <div className="flex flex-col items-start mt-2 space-y-2">
                  <Link
                    to="/services/interieur"
                    className="text-sm text-white hover:text-yellow-400"
                    onClick={closeMobileMenu}
                  >
                    Rénovation intérieur
                  </Link>
                  <Link
                    to="/services/exterieur"
                    className="text-sm text-white hover:text-yellow-400"
                    onClick={closeMobileMenu}
                  >
                    Rénovation extérieur
                  </Link>
                  <Link
                    to="/services/maconnerie"
                    className="text-sm text-white hover:text-yellow-400"
                    onClick={closeMobileMenu}
                  >
                    Maçonnerie
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/projects"
              className="flex items-center text-lg font-semibold text-yellow-400 hover:text-yellow-500"
              onClick={closeMobileMenu}
            >
              <FaProjectDiagram className="mr-3" /> Réalisations
            </Link>
            <Link
              to="/about"
              className="flex items-center text-lg font-semibold text-yellow-400 hover:text-yellow-500"
              onClick={closeMobileMenu}
            >
              <FaInfoCircle className="mr-3" /> À propos
            </Link>
            <Link
              to="/contact"
              className="flex items-center text-lg font-semibold text-yellow-400 hover:text-yellow-500"
              onClick={closeMobileMenu}
            >
              <FaPhone className="mr-3" /> Contact
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavbarComponent;
