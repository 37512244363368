import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  HomeModernIcon,
  PaintBrushIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/solid";

const Services = () => {
  const services = [
    {
      title: "Aménagement et Finitions Intérieures",
      description:
        "Transformez vos espaces de vie grâce à notre expertise en aménagement intérieur. Nous rénovons des salles de bains complètes, installons des carrelages élégants, et créons des cloisons en placo avec des finitions impeccables.",
      icon: <HomeModernIcon className="w-16 h-16 text-yellow-500" />,
      path: "/services/interieur",
    },
    {
      title: "Revêtements et Finitions Extérieures",
      description:
        "Donnez du caractère à vos extérieurs grâce à nos services de revêtement et d’enduit. Nos solutions durables et esthétiques protègent vos murs extérieurs tout en ajoutant une touche de style.",
      icon: <PaintBrushIcon className="w-16 h-16 text-yellow-500" />,
      path: "/services/exterieur",
    },
    {
      title: "Travaux de Maçonnerie et Structure",
      description:
        "Pour des rénovations solides et durables, nous vous proposons des services de maçonnerie adaptés à vos besoins. Du petit au gros œuvre, nos artisans réalisent des fondations, murs porteurs, et ajustements structurels.",
      icon: <BuildingLibraryIcon className="w-16 h-16 text-yellow-500" />,
      path: "/services/maconnerie",
    },
  ];

  return (
    <section className="py-16 bg-gradient-to-b from-gray-100 to-white">
      <div className="container mx-auto text-center">
        {/* Nouveau Titre et Description */}
        <motion.h2
          className="text-5xl font-extrabold text-gray-900 mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Redéfinissez Vos Espaces
        </motion.h2>
        <motion.p
          className="text-lg text-gray-700 mb-12 leading-relaxed max-w-3xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          Explorez une gamme complète de services alliant innovation, esthétisme
          et fonctionnalité. Qu'il s'agisse d'aménagements intérieurs, de
          finitions extérieures ou de maçonnerie, chaque projet est réalisé avec
          soin et précision pour transformer vos idées en réalité.
        </motion.p>

        {/* Grid des Cartes */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <div className="p-6 flex flex-col items-center">
                {/* Icône */}
                <div className="mb-4">{service.icon}</div>
                {/* Titre */}
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                  {service.title}
                </h3>
                {/* Description */}
                <p className="text-gray-600 leading-relaxed text-center mb-6">
                  {service.description}
                </p>
                {/* Bouton "En savoir plus" */}
                <Link
                  to={service.path}
                  className="text-yellow-500 hover:text-yellow-600 font-medium"
                >
                  En savoir plus →
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
